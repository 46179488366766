<template>
  <div>
  <v-data-table
    :headers="headers"
    :items="sections"
    sort-by="section_name"
    class="elevation-1"
    loading="isLoading"
    dense
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Sections</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        color="primary"
        class="mr-2"
        small
        @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon
        small
        color="red"
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
  </v-data-table>
  <create-section></create-section>
  </div>
</template>

<script>
import createSection from './createSection.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-section': createSection
	},
	data(){
		return{
			search: ''
		};
	},
	computed: {
		...mapGetters({
			sections: 'section/sections',
			headers: 'section/headers',
			editedItem: 'section/editedItem',
			defaultItem: 'section/defaultItem',
			dialog: 'section/dialog',
			editedIndex: 'section/editedIndex',
			isLoading: 'section/isLoading',
			valid: 'section/valid'
		}),
	},
	mounted () {
		this.$store.dispatch('section/getSections');
		this.$store.dispatch('dept/getDepts');
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('section/setEditedIndex',this.sections.indexOf(item));
			this.$store.dispatch('section/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('section/setDialog',true);
			this.$store.dispatch('section/setValid',true);
			this.$store.dispatch('section/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete!',
				text: item.section_code + '(' + item.section_name + ')' + '? You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('section/setEditedIndex',this.sections.indexOf(item));
					this.$store.dispatch('section/deleteSection',item);
				}
			});
		}
	}
};
</script>