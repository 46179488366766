<template>
	<div class="home">
		<table-section></table-section>
	</div>
</template>

<script>
import tableSection from '@/components/pages/maint/section/tableSection.vue';

export default {
	components: {
		'table-section': tableSection,
	}
};
</script>

<style>

</style>
